import React from "react";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { GatsbyImage } from "gatsby-plugin-image";
import { motion } from "framer-motion";
import { Link } from "gatsby-plugin-react-i18next";
import "./hero.scss";

export default function Hero({
    heroTitle,
    heroImage,
    heroDescription,
    buttonLink,
    buttonText,
    isSvg,
    heroContent,
    heroStyle,
}) {
    let img;
    if (isSvg) {
        img = heroImage;
    } else {
        img = <GatsbyImage image={heroImage} alt="hero" />;
    }
    return (
        <section
            id="hero"
            className="block hero-block bg-accent outer"
            style={heroStyle}
        >
            <div className="inner">
                <div className="grid">
                    <div className="cell block-preview">{img}</div>
                    <div className="cell block-content">
                        <Typography variant="h2" className="block-title">
                            {heroTitle}
                        </Typography>
                        <Typography
                            style={{ color: "#ffd700" }}
                            variant="h2"
                            className="block-title underline"
                        >
                            for Ukraine
                        </Typography>
                        <Typography
                            sx={{ marginTop: "1em", marginBottom: "1em" }}
                            variant="subtitle1"
                            component="div"
                        >
                            {heroDescription}
                        </Typography>
                        <motion.button
                            style={{
                                border: "none",
                                backgroundColor: "#0092ff",
                            }}
                            whileHover={{
                                scale: 1.1,
                            }}
                            whileTap={{ scale: 0.9 }}
                        >
                            {buttonLink ? (
                                <Link to={buttonLink}>
                                    <Button
                                        className="hero-button"
                                        size="large"
                                        variant="contained"
                                        color="secondary"
                                    >
                                        <Typography
                                            color="primary"
                                            variant="button"
                                        >
                                            {buttonText}
                                        </Typography>
                                    </Button>
                                </Link>
                            ) : null}
                        </motion.button>
                    </div>
                </div>
                {heroContent}
            </div>
        </section>
    );
}
