import React, { useEffect } from "react";
import { graphql } from "gatsby";
import { useTranslation } from "react-i18next";
import { useI18next } from "gatsby-plugin-react-i18next";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { motion } from "framer-motion";
import "../../components/landing-page/feature/features.scss";

export const query = graphql`
    query Locales($language: String!) {
        locales: allLocale(filter: { language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;

export default function DonateButton() {
    const { language, t: translate } = useI18next();
    useEffect(() => {
        const paperformScript = document.createElement("script");
        paperformScript.src = "https://paperform.co/__embed.min.js";
        document.body.appendChild(paperformScript);
        
        const importedScript = document.createElement("script");
        importedScript.src =
            "https://www.paypalobjects.com/donate/sdk/donate-sdk.js";
        importedScript.type = "application/javascript";
        importedScript.onload = () => {
            const renderingScript = document.createElement("script");
            renderingScript.innerHTML = `
                PayPal.Donation.Button(
                {
                    env: 'production',
                    hosted_button_id: 'APAQ6S7G7CQ2U',
                    image: {
                        src: 'https://www.paypalobjects.com/en_US/i/btn/btn_donateCC_LG.gif',
                        alt: 'Donate with PayPal button',
                        title: 'PayPal - The safer, easier way to pay online!',
                    }
                }
            ).render('#donate-button');`;
            document.body.appendChild(renderingScript);
        };
        document.body.appendChild(importedScript);
    });
    return (
        <>
            <script
                src="https://www.paypalobjects.com/donate/sdk/donate-sdk.js"
                charSet="UTF-8"
            />
            <div className="block-item">
                <div>
                    <Typography
                        variant="h3"
                        align="center"
                        marginTop="4rem"
                        marginBottom="2rem"
                    >
                        {translate("how-to-support")}
                    </Typography>
                </div>
                <Typography
                    sx={{
                        marginTop: "1rem",
                        marginBottom: "1rem",
                        maxWidth: "80%",
                    }}
                    display="flex"
                    justifyContent="center"
                    width="100%"
                    margin="0 auto"
                    variant="subtitle1"
                    component="div"
                >
                    {translate("health-professionals-are-invited")}
                </Typography>
                <motion.button
                    style={{
                        margin: "0 auto",
                        display: "flex",
                        border: "none",
                        backgroundColor: "#fff",
                    }}
                    whileHover={{
                        scale: 1.1,
                    }}
                    whileTap={{ scale: 0.9 }}
                >
                    <Button
                        data-paperform-id={
                            language === "en" ? "f9h2uqbm" : "aryt0zbc"
                        }
                        data-popup-button="1"
                        sx={{ marginTop: "1.5em" }}
                        size="large"
                        variant="contained"
                    >
                        <Typography variant="button" component="div">
                            {translate("join-now")}
                        </Typography>
                    </Button>
                </motion.button>
                <Typography
                    sx={{
                        marginTop: "2rem",
                        marginBottom: "1rem",
                        maxWidth: "80%",
                    }}
                    display="flex"
                    justifyContent="center"
                    width="100%"
                    margin="0 auto"
                    variant="subtitle1"
                    component="div"
                >
                    {translate("contribute-to-this-cause")}
                </Typography>
            </div>
            <div id="donate-button-container">
                <div
                    id="donate-button"
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        paddingBottom: "3rem",
                    }}
                ></div>
            </div>
        </>
    );
}
