import * as React from "react";
import { graphql } from "gatsby";
import Layout from '../components/layout/layout.jsx';
import Hero from "../components/hero/hero.jsx";
import Feature from "../components/landing-page/feature/feature.jsx";
import SwiperSlider from "../components/landing-page/doctor-slider/slider.jsx";
import { useTranslation } from "gatsby-plugin-react-i18next";
import DonateButton from "../components/donate-button/index.jsx";
import SEO from "../components/seo"

export const query = graphql`
 query HeroAndArticlesAndLocale($language:String!) {
  allContentfulDoctorCard(filter: {node_locale: {eq: $language}}) {
    nodes {
      name
      specialty
      image {
        gatsbyImageData(placeholder: DOMINANT_COLOR)
      }
      slug
          gatsbyPath(filePath: "/doctors/{ContentfulDoctorCard.slug}")
    }
  }
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;


export default function Index({ data: { allContentfulDoctorCard } }) {
  const translate = useTranslation().t;
  return (
    <Layout>
      <SEO title="Digital Clinic for Ukraine" />
      <main main id="content" className="site-content" >
        <Hero
          heroImage={
            <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="100%" height="483" viewBox="0 0 681 483">
              <defs>
                <clipPath id="clipPath">
                  <rect id="Rectangle_2" data-name="Rectangle 2" width="858" height="483" transform="translate(0.472 -0.131)" />
                </clipPath>
                <clipPath id="clip-Artboard_1">
                  <rect width="681" height="483" />
                </clipPath>
              </defs>
              <g id="Artboard_1" data-name="Artboard – 1" clipPath="url(#clip-Artboard_1)">
                <rect width="681" height="483" fill="#fff" />
                <g id="Hero_Image_-_Digital_Clinic_for_Ukraine" data-name="Hero Image - Digital Clinic for Ukraine" transform="translate(-82.472 0.131)" clipPath="url(#clipPath)">
                  <rect id="Rectangle_1" data-name="Rectangle 1" width="858" height="483" transform="translate(0.472 -0.131)" fill="#fff" />
                  <g id="_3786817" data-name="3786817" transform="translate(0.472 -43.654)">
                    <g id="BACKGROUND" transform="translate(0 0)">
                      <rect id="Rectangle_1-2" data-name="Rectangle 1" width="858" height="571" transform="translate(0 -0.477)" fill="#62c5cc" />
                      <path id="Path_1" data-name="Path 1" d="M805.154,472.28,780.674,88.052a33.485,33.485,0,0,0-39.921-30.77L85.423,185.509a33.661,33.661,0,0,0-26.881,28.826L35.435,402.16a33.605,33.605,0,0,0,30.084,37.519l702.914,68.175a33.646,33.646,0,0,0,36.72-35.575Z" transform="translate(4.52 7.115)" fill="#62c5cc" opacity="0.35" />
                    </g>
                    <g id="OBJECTS" transform="translate(80.105 73.775)">
                      <g id="Group_3" data-name="Group 3" transform="translate(0 13.155)">
                        <line id="Line_1" data-name="Line 1" x2="605.34" transform="translate(37.062 379.539)" fill="none" stroke="#280f49" strokeMiterlimit="10" strokeWidth="1" />
                        <line id="Line_2" data-name="Line 2" x2="605.34" transform="translate(37.062 370.96)" fill="none" stroke="#280f49" strokeMiterlimit="10" strokeWidth="1" />
                        <path id="Path_2" data-name="Path 2" d="M70.5,102.9h77.9a23.774,23.774,0,0,1,23.793,23.793V444.119H116.025" transform="translate(-70.5 -73.159)" fill="none" stroke="#280f49" strokeMiterlimit="10" strokeWidth="1" />
                        <path id="Path_3" data-name="Path 3" d="M138.306,206.8a7.176,7.176,0,0,0-7.206,7.206v34.316a7.206,7.206,0,0,0,14.413,0V214.006a7.176,7.176,0,0,0-7.206-7.206Z" transform="translate(-61.781 -58.21)" fill="none" stroke="#280f49" strokeMiterlimit="10" strokeWidth="1" />
                        <rect id="Rectangle_2-2" data-name="Rectangle 2" width="29.512" height="80.3" transform="translate(116.676 47.128)" fill="none" stroke="#280f49" strokeMiterlimit="10" strokeWidth="1" />
                        <path id="Path_4" data-name="Path 4" d="M186.752,143.6h0A10.74,10.74,0,0,0,176,154.352v36.835a10.74,10.74,0,0,0,10.752,10.753h0A10.74,10.74,0,0,0,197.5,191.187V154.352A10.813,10.813,0,0,0,186.752,143.6Z" transform="translate(-55.321 -67.303)" fill="#0071d3" />
                        <g id="Group_1" data-name="Group 1" transform="translate(559.243 246.849)">
                          <path id="Path_5" data-name="Path 5" d="M567.636,292.7h99.518a8.2,8.2,0,0,1,8.236,8.236V408.577a8.2,8.2,0,0,1-8.236,8.236H567.636a8.2,8.2,0,0,1-8.236-8.238V300.934A8.35,8.35,0,0,1,567.636,292.7Z" transform="translate(-559.4 -292.7)" fill="none" stroke="#280f49" strokeMiterlimit="10" strokeWidth="1" />
                          <path id="Path_6" data-name="Path 6" d="M576.348,333.382h79.614a5.908,5.908,0,0,0,5.948-5.948V311.648a5.908,5.908,0,0,0-5.948-5.948H576.348a5.908,5.908,0,0,0-5.948,5.948v15.786A6.057,6.057,0,0,0,576.348,333.382Z" transform="translate(-557.817 -290.829)" fill="none" stroke="#280f49" strokeMiterlimit="10" strokeWidth="1" />
                          <path id="Path_7" data-name="Path 7" d="M576.348,398.582h79.614a5.908,5.908,0,0,0,5.948-5.948V344.248a5.908,5.908,0,0,0-5.948-5.948H576.348a5.908,5.908,0,0,0-5.948,5.948v48.386A5.98,5.98,0,0,0,576.348,398.582Z" transform="translate(-557.817 -286.139)" fill="none" stroke="#280f49" strokeMiterlimit="10" strokeWidth="1" />
                          <rect id="Rectangle_3" data-name="Rectangle 3" width="19.789" height="5.033" transform="translate(67.886 33.714) rotate(180)" fill="none" stroke="#280f49" strokeMiterlimit="10" strokeWidth="1" />
                          <rect id="Rectangle_4" data-name="Rectangle 4" width="19.789" height="5.033" transform="translate(92.308 92.221) rotate(-90)" fill="none" stroke="#280f49" strokeMiterlimit="10" strokeWidth="1" />
                        </g>
                        <g id="Group_2" data-name="Group 2" transform="translate(498.274)">
                          <path id="Path_8" data-name="Path 8" d="M619.8,76.9H518.225c-6.635,0-12.125,6.634-12.125,14.642v40.15c0,8.122,5.376,14.642,12.125,14.642H619.8c6.635,0,12.125-6.635,12.125-14.642V91.656C631.927,83.534,626.551,76.9,619.8,76.9Z" transform="translate(-506.1 -76.9)" fill="none" stroke="#fff" strokeMiterlimit="10" strokeWidth="1" />
                          <rect id="Rectangle_5" data-name="Rectangle 5" width="40.379" height="12.811" transform="translate(59.825 16.929)" fill="none" stroke="#fff" strokeMiterlimit="10" strokeWidth="1" />
                        </g>
                      </g>
                      <g id="Group_9" data-name="Group 9" transform="translate(207.504 0)">
                        <path id="Path_9" data-name="Path 9" d="M389.625,131.6H262.654a10.739,10.739,0,0,0-10.524,12.926l18.531,91.625a9.313,9.313,0,0,0,9.151,7.435H412.159Z" transform="translate(-251.905 -55.875)" fill="#865073" />
                        <path id="Path_10" data-name="Path 10" d="M420.839,238.784,399.906,134.807a4.062,4.062,0,0,0-4-3.2H264.013a4.077,4.077,0,0,0-4,4.8l20.933,103.977a4.061,4.061,0,0,0,4,3.2H416.836a4.077,4.077,0,0,0,4-4.8Z" transform="translate(-250.748 -55.877)" fill="#fff" />
                        <path id="Path_11" data-name="Path 11" d="M284.56,239.992a1,1,0,0,1-.915-.686l-20.933-103.98a.646.646,0,0,1,.229-.686.978.978,0,0,1,.686-.343h131.89a1,1,0,0,1,.915.686l20.933,103.977a.645.645,0,0,1-.229.686.977.977,0,0,1-.686.343Z" transform="translate(-250.362 -55.484)" fill="#0071d3" />
                        <path id="Path_12" data-name="Path 12" d="M304.693,178.454H286.848L280.9,150.2h17.845Z" transform="translate(-247.731 -53.199)" fill="none" stroke="#62c5cc" strokeMiterlimit="10" strokeWidth="1" />
                        <g id="Group_8" data-name="Group 8" transform="translate(53.987 93.661)">
                          <path id="Path_13" data-name="Path 13" d="M337.2,176.655s11.21,2.631,16.7,5.605c0,0,6.177-7.435,8.236-10.411,2.059-3.088,5.605-8.693,5.834-8.35s5.605,1.832,7.321,6.863c0,0-10.18,24.365-14.87,25.852s-22.191-4.232-22.191-4.232S337.314,176.884,337.2,176.655Z" transform="translate(-293.618 -144.946)" fill="#fff" />
                          <path id="Path_14" data-name="Path 14" d="M365.708,167.436s-1.716-2.173-1.373-3.887c.458-1.832-.686-5.262-.114-5.719.686-.458,1.487.915,2.517,2.974a3.147,3.147,0,0,0,1.487-1.6c.458-1.144,2.631-4.461,3.432-4.692s.915.229,1.832,0c1.028-.229,1.832,0,1.832,1.144-.114,1.144.572,1.6.458,2.974s-3.775,9.494-7.435,10.066A2.809,2.809,0,0,1,365.708,167.436Z" transform="translate(-289.754 -146.251)" fill="#ffadbf" />
                          <path id="Path_15" data-name="Path 15" d="M304.826,183.091s11.324-8.122,15.557-8.465,19.217-.343,22.077.572,6.063,41.294,6.063,41.294h-35.8S303.11,188.811,304.826,183.091Z" transform="translate(-298.306 -143.376)" fill="#fff" />
                          <path id="Path_16" data-name="Path 16" d="M319.479,174.641c-1.945.114-5.491,2.059-8.579,3.887,3.317,4.118,14.3,17.5,18.3,24.593,0,0,3.546-18.073,2.173-28.826C326.571,174.3,321.538,174.412,319.479,174.641Z" transform="translate(-297.402 -143.39)" fill="#865073" />
                          <path id="Path_17" data-name="Path 17" d="M328.879,195.533c-1.716-4-5.719-13.727-5.719-13.727l.343-2.4-3.2,1.258,1.487,1.832,2.059,13.841a63.873,63.873,0,0,1,3.887,6.063C327.735,202.509,328.307,199.649,328.879,195.533Z" transform="translate(-296.05 -142.661)" fill="#280f49" />
                          <path id="Path_18" data-name="Path 18" d="M308.945,180.2a19.768,19.768,0,0,0-1.144,5.834s3.088.686,5.834,1.028c0,0-.686,3.432,0,5.605a140.952,140.952,0,0,1,15.557,9.952" transform="translate(-297.849 -142.543)" fill="none" stroke="#0071d3" strokeMiterlimit="10" strokeWidth="0.5" />
                          <path id="Path_19" data-name="Path 19" d="M329.845,174.9a14.509,14.509,0,0,1,4.232,4,15.538,15.538,0,0,1-2.745,4.461,13.71,13.71,0,0,1,2.173,3.089s-3.546,10.867-6.406,16.929" transform="translate(-295.071 -143.306)" fill="none" stroke="#0071d3" strokeMiterlimit="10" strokeWidth="0.5" />
                          <path id="Path_20" data-name="Path 20" d="M305.62,182s-5.948,4.118-5.834,8.579S299.1,215.4,299.1,215.4h12.354s-.686-20.7-1.258-23.564C309.511,188.978,305.62,182,305.62,182Z" transform="translate(-299.1 -142.284)" fill="#fff" />
                          <path id="Path_21" data-name="Path 21" d="M310.058,214.164s-.686-20.7-1.258-23.564" transform="translate(-297.704 -141.047)" fill="none" stroke="#0071d3" strokeMiterlimit="10" strokeWidth="0.5" />
                          <path id="Path_22" data-name="Path 22" d="M312.7,166.7c0,.343,3.546,11.782,3.546,11.782s2.288,3.66,6.062,2.631a4.166,4.166,0,0,0,3.2-4.692l-2.4-8.349Z" transform="translate(-297.143 -144.486)" fill="#ffadbf" />
                          <path id="Path_23" data-name="Path 23" d="M325.166,174.936l-1.945-6.978L312.7,166.7c0,.229.915,3.317,1.832,6.291A12.834,12.834,0,0,0,325.166,174.936Z" transform="translate(-297.143 -144.486)" fill="#ffadbf" />
                          <path id="Path_24" data-name="Path 24" d="M317.011,150.311s7.435-1.028,9.38,4.232,2.173,9.265,2.631,10.981-.686,8.122-3.887,9.723c-3.2,1.716-11.9-2.745-12.926-8.007S307.288,153.17,317.011,150.311Z" transform="translate(-297.451 -146.856)" fill="#ffadbf" />
                          <path id="Path_25" data-name="Path 25" d="M321.659,153.938s.686-2.059,4.232-.8c0,0,.572-4.919-1.6-5.491-2.173-.458-11.324-1.258-15.326,3.2a3.05,3.05,0,0,0-.458,2.745s-2.517,2.059-1.258,4.8-.343,12.811,6.52,12.467l-.915-2.631s2.059.229,1.258-2.517-2.974-6.863-1.716-9.151C313.766,154.166,316.054,151.993,321.659,153.938Z" transform="translate(-297.981 -147.28)" fill="#280f49" />
                          <path id="Path_26" data-name="Path 26" d="M314.1,167.277l-1.6-4.919s-.915-1.716-2.288-1.144C308.838,161.9,308.381,168.764,314.1,167.277Z" transform="translate(-297.628 -145.291)" fill="#ffadbf" />
                          <g id="Group_4" data-name="Group 4" transform="translate(17.044 9.203)">
                            <path id="Path_27" data-name="Path 27" d="M314,156.555a1.947,1.947,0,0,1,1.487-1.144,1.588,1.588,0,0,1,1.028,0,3.426,3.426,0,0,1,.915.458,8.1,8.1,0,0,0-1.716.114A7.559,7.559,0,0,0,314,156.555Z" transform="translate(-314 -155.326)" fill="#280f49" />
                          </g>
                          <g id="Group_5" data-name="Group 5" transform="translate(25.579 7.222)">
                            <path id="Path_28" data-name="Path 28" d="M321.507,154.963a.889.889,0,0,1,.229-.915,1.412,1.412,0,0,1,.915-.458,2.23,2.23,0,0,1,.915.114,2.868,2.868,0,0,1,.8.458,7.471,7.471,0,0,0-1.6,0A1.335,1.335,0,0,0,321.507,154.963Z" transform="translate(-321.464 -153.582)" fill="#280f49" />
                          </g>
                          <path id="Path_29" data-name="Path 29" d="M317.735,159.7c.114.572,0,1.028-.229,1.144-.343.114-.686-.343-.8-.8-.114-.572,0-1.028.229-1.144S317.621,159.131,317.735,159.7Z" transform="translate(-296.573 -145.61)" fill="#280f49" />
                          <path id="Path_30" data-name="Path 30" d="M324.335,157.9c.114.572,0,1.028-.229,1.144-.343.114-.686-.343-.8-.8-.114-.572,0-1.028.229-1.144S324.221,157.331,324.335,157.9Z" transform="translate(-295.623 -145.869)" fill="#280f49" />
                          <g id="Group_6" data-name="Group 6" transform="translate(16.359 8.096)">
                            <ellipse id="Ellipse_1" data-name="Ellipse 1" cx="3.432" cy="4.004" rx="3.432" ry="4.004" transform="translate(0 3.254) rotate(-10.254)" fill="none" stroke="#280f49" strokeMiterlimit="10" strokeWidth="1" />
                            <path id="Path_31" data-name="Path 31" d="M327.231,157.838c.343,2.173-.572,4.232-2.173,4.461-1.6.343-3.2-1.258-3.546-3.432s.572-4.232,2.173-4.461C325.171,154.064,326.772,155.55,327.231,157.838Z" transform="translate(-312.247 -154.357)" fill="none" stroke="#280f49" strokeMiterlimit="10" strokeWidth="1" />
                            <path id="Path_32" data-name="Path 32" d="M319.8,159.333s.686-1.832,1.945-.686" transform="translate(-312.481 -153.793)" fill="none" stroke="#280f49" strokeMiterlimit="10" strokeWidth="1" />
                          </g>
                          <g id="Group_7" data-name="Group 7" transform="translate(25.28 14.894)">
                            <path id="Path_33" data-name="Path 33" d="M321.2,160.3a5.013,5.013,0,0,0,2.516,1.373c.114,0,.229,0,.572.229a.552.552,0,0,1,0,.458l-.229.229a.989.989,0,0,1-.686.343,7.4,7.4,0,0,1-1.487.458c.458-.343.915-.572,1.258-.8a1.141,1.141,0,0,0,.572-.458l.114-.114v.114c.114.114-.114,0-.114,0a2.994,2.994,0,0,1-1.487-.686A1.34,1.34,0,0,1,321.2,160.3Z" transform="translate(-321.2 -160.3)" fill="#280f49" />
                          </g>
                          <path id="Path_34" data-name="Path 34" d="M320.2,165.258a4.272,4.272,0,0,0,3.775-.458" transform="translate(-296.064 -144.759)" fill="none" stroke="#fff" strokeMiterlimit="10" strokeWidth="0.5" />
                          <path id="Path_35" data-name="Path 35" d="M322.3,180.046s-1.945,4-2.173,3.887c-.229,0-5.262-2.517-6.52-7.092-.114-.343-.229-2.745,1.6-2.745C315.092,174.1,318.526,180.046,322.3,180.046Z" transform="translate(-297.021 -143.419)" fill="#f3c6ff" />
                          <path id="Path_36" data-name="Path 36" d="M323.673,173s.457,4.919-2.173,6.978c0,0,2.517,2.059,3.432,1.945s1.832-4.461,1.144-7.435A2.539,2.539,0,0,0,323.673,173Z" transform="translate(-295.877 -143.58)" fill="#f3c6ff" />
                        </g>
                        <path id="Path_37" data-name="Path 37" d="M287.725,151.963a6.063,6.063,0,1,1-6.063-6.063A6.063,6.063,0,0,1,287.725,151.963Z" transform="translate(-248.494 -53.818)" fill="#62c5cc" />
                        <rect id="Rectangle_6" data-name="Rectangle 6" width="22.191" height="13.841" transform="translate(82.012 187.711)" fill="#865073" />
                        <rect id="Rectangle_7" data-name="Rectangle 7" width="11.21" height="13.841" transform="translate(92.994 187.711)" fill="#865073" />
                        <path id="Path_38" data-name="Path 38" d="M480.853,65.4H351.846a7.106,7.106,0,0,0-7.046,7.046v53.3a7.106,7.106,0,0,0,7.046,7.046h4.74l-6.79,14.348,23.444-14.348H480.853a7.106,7.106,0,0,0,7.046-7.046v-53.3a7.026,7.026,0,0,0-7.046-7.046Z" transform="translate(-246.201 -68.4)" fill="#ffd700" />
                        <path id="Path_39" data-name="Path 39" d="M396.417,134.983a.87.87,0,0,0-.915-.686H263.613a.978.978,0,0,0-.686.343.967.967,0,0,0-.229.686l.915,4.8h133.72Z" transform="translate(-250.348 -55.484)" fill="#f3c6ff" />
                        <path id="Path_40" data-name="Path 40" d="M281.647,228.776a.87.87,0,0,0,.915.686H414.451a.978.978,0,0,0,.686-.343.967.967,0,0,0,.229-.686L411.134,207.5H277.3Z" transform="translate(-248.249 -44.955)" fill="#f3c6ff" />
                      </g>
                      <g id="Group_12" data-name="Group 12" transform="translate(122.395 201.617)">
                        <g id="Group_10" data-name="Group 10" transform="translate(0 0)">
                          <path id="Path_41" data-name="Path 41" d="M589.413,469.8H492.982V279.34a22.505,22.505,0,0,0-22.534-22.534H293.032c-12.466,0-19.675,12.7-19.675,22.534V469.8H177.5V279.34a37.663,37.663,0,0,1,37.634-37.634H470.448c20.819,0,122.4-2.974,118.963,37.634Z" transform="translate(-177.5 -241.657)" fill="#865073" />
                        </g>
                        <rect id="Rectangle_8" data-name="Rectangle 8" width="307.932" height="32.257" transform="translate(103.979 15.148)" fill="#fff" />
                        <g id="Group_11" data-name="Group 11" transform="translate(91.396 0.049)">
                          <path id="Path_42" data-name="Path 42" d="M588.1,469.79h-15.1V279.334A22.505,22.505,0,0,0,550.46,256.8H295.034A22.505,22.505,0,0,0,272.5,279.334V469.79H257.4V279.334A37.663,37.663,0,0,1,295.034,241.7H550.348a37.663,37.663,0,0,1,37.634,37.634V469.79Z" transform="translate(-257.4 -241.7)" fill="#f3c6ff" />
                        </g>
                        <line id="Line_3" data-name="Line 3" y2="32.143" transform="translate(257.945 15.148)" fill="none" stroke="#f3c6ff" strokeMiterlimit="10" strokeWidth="1" />
                        <ellipse id="Ellipse_2" data-name="Ellipse 2" cx="2.745" cy="6.978" rx="2.745" ry="6.978" transform="translate(246.735 23.727)" fill="#865073" />
                        <ellipse id="Ellipse_3" data-name="Ellipse 3" cx="2.745" cy="6.978" rx="2.745" ry="6.978" transform="translate(263.779 23.727)" fill="#865073" />
                      </g>
                      <g id="Group_14" data-name="Group 14" transform="translate(76.64 214.477)">
                        <path id="Path_43" data-name="Path 43" d="M229.777,373.106l-1.144,8.579-.8,5.948-.572,4.575-.343,2.4-.458,3.546-.8,5.946-.572,4-.343,2.4-.572,4.232-.8,5.948L219.253,451.8H150.62L146.5,420.692l-.8-5.948-.572-4.232-.343-2.4-.572-4-.8-5.948-.458-3.546-.343-2.516-.572-4.576-.8-5.948-1.144-8.579a6.262,6.262,0,0,1,6.178-7.092h76.983A6.477,6.477,0,0,1,229.777,373.106Z" transform="translate(-137.122 -236.642)" fill="#0071d3" />
                        <path id="Path_44" data-name="Path 44" d="M153.4,382.273c0-1.144-13.841-23.45-15.9-55.249,0,0,10.752,37.062,15.9,43.124,0,0-11.324-62.227,0-98.488,0,0,3.775,66.574,11.553,87.736,0,0-2.631-76.983,10.981-106.5,0,0,2.288,76.184,11.553,106.266,0,0-3.775-40.836,2.059-71.264,0,0,10.752,45.412,8.693,66.917,0,0,8.922-37.634,5.834-57.194,0,0,10.752,8.007,7.551,62.113,0,0,8.693-23.793,7.206-42.095,0,0,11.553,12.926-4,64.745H153.4Z" transform="translate(-137.5 -252.9)" fill="#f3c6ff" />
                        <g id="Group_13" data-name="Group 13" transform="translate(4.461 145.044)">
                          <path id="Path_45" data-name="Path 45" d="M228.449,379.7l-.8,5.948H142.2l-.8-5.948Z" transform="translate(-141.4 -379.7)" fill="#fff" />
                          <path id="Path_46" data-name="Path 46" d="M226.9,388.9l-.343,2.4H142.943l-.343-2.4Z" transform="translate(-141.227 -378.376)" fill="#fff" />
                          <path id="Path_47" data-name="Path 47" d="M224.586,402.9l-.343,2.4h-79.5l-.343-2.4Z" transform="translate(-140.968 -376.362)" fill="#fff" />
                          <path id="Path_48" data-name="Path 48" d="M226,394.2l-.8,5.948H144.1l-.8-5.948Z" transform="translate(-141.127 -377.614)" fill="#fff" />
                          <path id="Path_49" data-name="Path 49" d="M223.556,408.7l-.8,5.948H146l-.8-5.948Z" transform="translate(-140.853 -375.527)" fill="#fff" />
                        </g>
                      </g>
                      <g id="Group_17" data-name="Group 17" transform="translate(428.383 108.325)">
                        <path id="Path_50" data-name="Path 50" d="M587.512,160.1H513.617A19.186,19.186,0,0,0,494.4,179.317h0a19.186,19.186,0,0,0,19.217,19.217h73.895a19.186,19.186,0,0,0,19.217-19.217h0A19.333,19.333,0,0,0,587.512,160.1Z" transform="translate(-437.892 -160.1)" fill="#865073" />
                        <path id="Path_51" data-name="Path 51" d="M579.193,160.2a19.22,19.22,0,0,0-17.844,19.1h0a17.886,17.886,0,1,0,35.689,0h0A19.226,19.226,0,0,0,579.193,160.2Z" transform="translate(-428.201 -160.086)" fill="#865073" />
                        <path id="Path_52" data-name="Path 52" d="M614.523,213.415A17.415,17.415,0,0,0,597.136,196.6H518.551a15.192,15.192,0,0,0-15.214,15.786c.8,20.475,1.028,57.08-4.8,85.791a28.315,28.315,0,0,1-27.8,22.649h-12.7a13.04,13.04,0,0,0,0,26.081h112.1a41.858,41.858,0,0,0,41.522-36.6C615.781,277.243,615.209,235.949,614.523,213.415Z" transform="translate(-445 -154.848)" fill="#865073" />
                        <path id="Path_53" data-name="Path 53" d="M588.327,292.343c4.118-32.484,3.661-72.865,2.86-95.743H518.436a15.192,15.192,0,0,0-15.214,15.786c.8,20.475,1.028,57.08-4.8,85.791a28.315,28.315,0,0,1-27.8,22.649h-12.7a13.018,13.018,0,0,0-12.125,8.122h101a41.859,41.859,0,0,0,41.522-36.6Z" transform="translate(-444.885 -154.848)" fill="#865073" />
                        <g id="Group_16" data-name="Group 16" transform="translate(21.811 191.943)">
                          <path id="Path_54" data-name="Path 54" d="M488.8,327.9a11.886,11.886,0,0,0,11.9,11.9h33.516a11.886,11.886,0,0,0,11.9-11.9Z" transform="translate(-460.509 -327.9)" fill="#674186" />
                          <g id="Group_15" data-name="Group 15" transform="translate(0 96.2)">
                            <path id="Path_55" data-name="Path 55" d="M564.812,427.214,525.234,412h-9.952L475.7,427.214a9.933,9.933,0,0,0-5.719,12.811l45.3-17.5v13.841a4.919,4.919,0,1,0,9.837,0V422.524l45.3,17.387h0A9.739,9.739,0,0,0,564.812,427.214Z" transform="translate(-463.313 -412)" fill="#674186" />
                            <circle id="Ellipse_4" data-name="Ellipse 4" cx="8.236" cy="8.236" r="8.236" transform="translate(0 32.013) rotate(-76.761)" fill="#280f49" />
                            <circle id="Ellipse_5" data-name="Ellipse 5" cx="8.236" cy="8.236" r="8.236" transform="translate(92.421 25.832) rotate(-45)" fill="#280f49" />
                          </g>
                          <rect id="Rectangle_9" data-name="Rectangle 9" width="15.214" height="85.105" transform="translate(49.453 12.011)" fill="#f3c6ff" />
                        </g>
                      </g>
                      <g id="Group_22" data-name="Group 22" transform="translate(369.243 70.508)">
                        <path id="Path_56" data-name="Path 56" d="M439.615,365.888c0,.915-16.815,69.319-16.815,69.319s6.747,8.122,21.963,3.66l27.8-75.267Z" transform="translate(-389.054 -93.003)" fill="#ffadbf" />
                        <path id="Path_57" data-name="Path 57" d="M455.872,403.932c-7.893-8.35-19.9-6.863-25.28-5.719-4,16.472-7.893,32.143-7.893,32.143s6.747,8.122,21.962,3.66Z" transform="translate(-389.069 -88.152)" fill="#fff" />
                        <path id="Path_58" data-name="Path 58" d="M427.046,426.245s-14.184,11.1-26.538,12.7c-12.466,1.6-5.6,5.491-2.631,6.063,3.088.572,49.643,0,49.643,0s2.631-4.8,1.487-15.1c0,0-1.6-6.634-7.435-2.86S427.046,426.245,427.046,426.245Z" transform="translate(-393.3 -84.041)" fill="#0071d3" />
                        <path id="Path_59" data-name="Path 59" d="M448.8,364.28c.686.686,40.15,58.91,40.15,58.91s10.524.458,17.5-13.841L469.275,338.2Z" transform="translate(-385.313 -96.657)" fill="#ffadbf" />
                        <path id="Path_60" data-name="Path 60" d="M488.762,375.6c-11.439.343-18.416,10.18-21.162,14.87,9.494,13.955,18.531,27.339,18.531,27.339s10.524.458,17.5-13.841Z" transform="translate(-382.608 -91.276)" fill="#fff" />
                        <path id="Path_61" data-name="Path 61" d="M485.247,414.437s-1.258,17.845-8.465,28.139.343,7.893,2.745,6.063c2.517-1.832,33.289-36.833,33.289-36.833s-1.716-5.147-10.181-11.21c0,0-6.063-3.2-7.206,3.546C494.512,411,485.247,414.437,485.247,414.437Z" transform="translate(-381.725 -87.789)" fill="#0071d3" />
                        <path id="Path_62" data-name="Path 62" d="M567.654,260.792c.229.8,8.922,34.316-15.442,48.5-24.365,14.07-32.029,23.335-30.885,28.368s9.037,34.316,12.125,43.467c0,0-47.128,32.6-99.4,8.122,0,0,3.432-18.531,0-27.8s-16.014-60.626-1.487-67.718S499.707,256.1,499.707,256.1Z" transform="translate(-388.635 -108.47)" fill="#009cd5" />
                        <path id="Path_63" data-name="Path 63" d="M448.8,336.862s7.893-18.76,15.214-24.71,30.542-21.046,38.778-30.652" transform="translate(-385.313 -104.816)" fill="none" stroke="#fff" strokeMiterlimit="10" strokeWidth="1" />
                        <path id="Path_64" data-name="Path 64" d="M572.753,206.78s11.21,6.52,13.727,6.978c2.516.343,6.863-9.837,12.583-17.387,5.948-7.893,17.387-27.569,17.387-27.569s7.092-.229,9.266,3.775-23.335,60.168-34.2,63.485C580.76,239.378,561.2,224.05,561.2,224.05Z" transform="translate(-369.141 -121.031)" fill="#ffadbf" />
                        <path id="Path_65" data-name="Path 65" d="M609.462,174.082a15.978,15.978,0,0,1-1.716-5.834c0-2.517-3.546-10.066-3.546-10.066s1.6-1.716,3.661,1.144c1.945,2.86,3.546,4.347,4.118,4.347s1.716-8.35,2.4-12.467,2.288-3.66,2.631-1.945c.343,1.6,1.144,4.919,1.716,4.232s1.945-3.317,3.089-1.832,2.745,2.288,3.66,3.66c.8,1.373.114,4.692,0,6.291s-3.432,15.671-8.465,16.472C611.978,178.658,609.462,174.082,609.462,174.082Z" transform="translate(-362.954 -124.025)" fill="#ffadbf" />
                        <path id="Path_66" data-name="Path 66" d="M487.593,159.7s.8,3.089-2.288,10.295c-3.088,7.321-9.723,29.283,14.413,29.055S510.13,159.7,510.13,159.7Z" transform="translate(-380.525 -122.34)" fill="#280f49" />
                        <path id="Path_67" data-name="Path 67" d="M483.868,226.7s-5.033,13.383-6.634,14.756c-1.6,1.487-22.992-5.262-48.043-6.747,0,0-4.576,4.8-2.631,10.524,0,0,46.9,24.021,58.338,18.186,15.214-7.778,17.845-29.855,17.845-29.855Z" transform="translate(-388.582 -112.7)" fill="#ffadbf" />
                        <path id="Path_68" data-name="Path 68" d="M429.18,243.774s-4.347,2.631-8.465,1.6-6.863,2.745-10.066,2.631.343-1.832,0-3.432-4.347.915-3.432-1.144c.915-2.173,7.092-6.978,12.583-8.579a40.61,40.61,0,0,1,12.125-1.144S434.785,239.885,429.18,243.774Z" transform="translate(-391.316 -111.701)" fill="#ffadbf" />
                        <path id="Path_69" data-name="Path 69" d="M501.082,189.6s-15.442,1.832-19.331,13.612-8.35,30.542-8.35,30.542,2.288,10.753,22.992,10.524l5.719-24.71Z" transform="translate(-381.774 -118.038)" fill="#0071d3" />
                        <path id="Path_70" data-name="Path 70" d="M491.373,163.37c-.458,0-11.782-4.919-12.467-19.1s16.586-18.186,28.483-17.044Z" transform="translate(-380.987 -127.04)" fill="#280f49" />
                        <path id="Path_71" data-name="Path 71" d="M499.437,190.376c-.114.458-6.52,2.173-7.092,8.579-.686,6.406.572,6.52-3.2,10.752-3.66,4.232-8.922,17.845,3.432,25.165,0,0,4.8,4.232,3.66,13.727-1.144,9.607-6.635,18.073-6.635,18.073s-.114,9.837,17.273,13.612,46.1-2.059,54.105-6.978c0,0-4.919-17.73-5.948-32.484s12.125-28.483,2.173-48.157c-2.4-4.692-9.723-9.494-18.645-8.236C529.406,185.571,499.437,190.376,499.437,190.376Z" transform="translate(-380.129 -118.814)" fill="#0071d3" />
                        <path id="Path_72" data-name="Path 72" d="M535.231,184.12s20.475-1.716,28.711,6.291a190.771,190.771,0,0,0,18.874,15.442s-1.487,21.619-14.07,24.71L545.64,217.752S521.5,195.559,535.231,184.12Z" transform="translate(-373.485 -118.849)" fill="#0071d3" />
                        <line id="Line_4" data-name="Line 4" x1="23.106" y1="12.811" transform="translate(172.155 98.901)" fill="none" stroke="#280f49" strokeMiterlimit="10" strokeWidth="1" />
                        <path id="Path_73" data-name="Path 73" d="M492.345,197.1c-.686,6.406.572,6.52-3.2,10.752-3.66,4.232-8.922,17.845,3.432,25.165,0,0,4.8,4.232,3.66,13.727-1.144,9.607-6.635,18.073-6.635,18.073" transform="translate(-380.129 -116.959)" fill="none" stroke="#280f49" strokeMiterlimit="10" strokeWidth="1" />
                        <path id="Path_74" data-name="Path 74" d="M502.806,188.9s-9.837,5.033-6.52,8.579c3.2,3.432,8.236-1.716,8.236-1.716s-4,5.147-1.028,7.551c2.86,2.4,7.321-3.66,7.321-3.66s-.686,7.778,3.887,6.978,3.317-8.465,3.317-8.465,2.059,6.291,5.834,3.887c3.775-2.288-1.028-8.35-1.028-8.35s3.66,5.147,5.948,2.745c2.288-2.517.457-6.52-2.517-7.551" transform="translate(-378.579 -118.137)" fill="none" stroke="#fff" strokeMiterlimit="10" strokeWidth="1" />
                        <path id="Path_75" data-name="Path 75" d="M503.428,176.1,502.4,196.917s2.745,6.747,9.837,6.52c7.092-.343,11.439-8.693,11.439-8.693L518.757,161.8Z" transform="translate(-377.601 -122.038)" fill="#ffadbf" />
                        <path id="Path_76" data-name="Path 76" d="M502.7,190.283a35.555,35.555,0,0,0,17.844-16.357L518.712,161.8l-15.326,14.3Z" transform="translate(-377.558 -122.038)" fill="#ffadbf" />
                        <path id="Path_77" data-name="Path 77" d="M497.231,134.059c-.458,0-13.727,1.832-12.926,16.815.915,14.87,2.86,29.4,15.1,38.091,0,0,27.682-7.092,21.162-33.744S497.231,134.059,497.231,134.059Z" transform="translate(-380.21 -126.097)" fill="#ffadbf" />
                        <path id="Path_78" data-name="Path 78" d="M490.4,134.536s10.411,19.9,20.59,22.649.343,31.114,6.978,37.634c6.52,6.52,16.93,13.727,11.553,36.032s6.978,26.881,6.978,26.881-2.745-16.472,2.631-26.881,2.86-14.184,2.86-14.184,4.232,5.033,2.059,13.727,2.745,18.99,2.745,18.99a38.726,38.726,0,0,1,1.144-15.671c2.517-8.922,3.432-38.777-3.089-46.213S532.724,174,534.9,162.105s-2.288-32.829-21.85-34.774C493.488,125.614,490.4,134.536,490.4,134.536Z" transform="translate(-379.328 -127.031)" fill="#280f49" />
                        <g id="Group_18" data-name="Group 18" transform="translate(105.467 24.43)">
                          <path id="Path_79" data-name="Path 79" d="M485.5,150.453a2.279,2.279,0,0,1,.915-1.373,3.417,3.417,0,0,1,1.6-.686,2.518,2.518,0,0,1,.915.114,2.866,2.866,0,0,1,.8.458,2.392,2.392,0,0,1,.8,1.487c-.458-.229-.915-.572-1.258-.686a4.063,4.063,0,0,0-1.144-.229A7.243,7.243,0,0,0,485.5,150.453Z" transform="translate(-485.5 -148.39)" fill="#280f49" />
                        </g>
                        <g id="Group_19" data-name="Group 19" transform="translate(117.935 23.519)">
                          <path id="Path_80" data-name="Path 80" d="M496.4,149.773a3.075,3.075,0,0,1,1.373-1.6,4.281,4.281,0,0,1,2.059-.572,7.269,7.269,0,0,1,2.059.686,6.872,6.872,0,0,1,1.487,1.487,7.9,7.9,0,0,1-.915-.343l-.915-.343a9.122,9.122,0,0,0-1.716-.343A6.622,6.622,0,0,0,496.4,149.773Z" transform="translate(-496.4 -147.6)" fill="#280f49" />
                        </g>
                        <path id="Path_81" data-name="Path 81" d="M488.834,158.159c0,1.144-.457,2.059-.915,2.059-.572,0-.915-.915-.915-2.059s.458-2.059.915-2.059S488.834,157.015,488.834,158.159Z" transform="translate(-379.819 -122.858)" fill="#280f49" />
                        <path id="Path_82" data-name="Path 82" d="M499.434,157.159c0,1.144-.458,2.059-.915,2.059-.572,0-.915-.915-.915-2.059s.457-2.059.915-2.059C499.089,155.1,499.434,156.015,499.434,157.159Z" transform="translate(-378.294 -123.002)" fill="#280f49" />
                        <g id="Group_20" data-name="Group 20" transform="translate(108.814 37.36)">
                          <path id="Path_83" data-name="Path 83" d="M492.973,159.7a6.113,6.113,0,0,1-.229,3.775,5.83,5.83,0,0,1-2.516,2.974,2.4,2.4,0,0,1-.686.458c-.114.114-.114.114-.114.229h0a2.132,2.132,0,0,0,.458.343,5.569,5.569,0,0,0,.8.343c1.144.343,2.288.572,3.546.915a13.922,13.922,0,0,1-3.775-.114,2.154,2.154,0,0,1-.915-.229,1.6,1.6,0,0,1-1.028-.8,1.262,1.262,0,0,1,0-.915,2.5,2.5,0,0,1,.343-.572,3.478,3.478,0,0,1,.8-.572,6.6,6.6,0,0,0,2.288-2.4A15.056,15.056,0,0,0,492.973,159.7Z" transform="translate(-488.428 -159.7)" fill="#280f49" />
                        </g>
                        <path id="Path_84" data-name="Path 84" d="M492.5,169.615s4.461.229,6.747-.915c0,0-.229,4.575-3.432,4.8C492.729,173.733,492.5,169.615,492.5,169.615Z" transform="translate(-379.026 -121.045)" fill="#fff" />
                        <g id="Group_21" data-name="Group 21" transform="translate(99.29 26.379)">
                          <ellipse id="Ellipse_6" data-name="Ellipse 6" cx="6.063" cy="8.236" rx="6.063" ry="8.236" transform="translate(0 1.487)" fill="none" stroke="#fff" strokeMiterlimit="10" strokeWidth="1" />
                          <path id="Path_85" data-name="Path 85" d="M508.427,158.336c0,4.576-3.089,8.236-6.863,8.236s-6.863-3.66-6.863-8.236,3.089-8.236,6.863-8.236S508.427,153.875,508.427,158.336Z" transform="translate(-477.999 -150.1)" fill="none" stroke="#fff" strokeMiterlimit="10" strokeWidth="1" />
                          <path id="Path_86" data-name="Path 86" d="M490.7,157.978s1.258-2.745,4.692-.572" transform="translate(-478.575 -149.17)" fill="none" stroke="#fff" strokeMiterlimit="10" strokeWidth="1" />
                        </g>
                      </g>
                    </g>
                    <text id="Як_почуваєшся_" data-name={translate("how-are-you")} transform="translate(389 110.523)" fill="#280f49" fontSize="18" fontFamily="SegoeUI, Segoe UI"><tspan x="0" y="0">{translate("how-are-you")}</tspan></text>
                  </g>
                </g>
              </g>
            </svg>
          }
          isSvg={true}
          heroTitle="Digital Clinic"
          heroDescription={translate("landing-page-subtitle")}
          buttonLink="/doctors"
          buttonText={translate("doctors")}
        />
        <Feature />
        <SwiperSlider elements={allContentfulDoctorCard.nodes} />
        <DonateButton />
      </main>
    </Layout >
  );
}