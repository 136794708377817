import React from "react";
import CardContent from '@mui/material/CardContent';
import { GatsbyImage } from "gatsby-plugin-image";
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import CardActionArea from '@mui/material/CardActionArea';
import { Link } from "gatsby-plugin-react-i18next";

export default function SliderCard({ content }) {
    return (
        <Card>
            <Link to={content.gatsbyPath}>
                <CardActionArea>
                    <CardMedia>
                        <GatsbyImage
                            image={content.image.gatsbyImageData}
                            alt={content.name}
                        />
                    </CardMedia>
                    <CardContent>
                        <Typography
                            align="center"
                            gutterBottom
                            variant="h6"
                            color="text.primary"
                        >
                            {content.name}
                        </Typography>
                        <Typography
                            align="center"
                            variant="body2"
                            color="text.secondary"
                        >
                            {content.specialty}
                        </Typography>
                    </CardContent>
                </CardActionArea>
            </Link>
        </Card>
    )
}
