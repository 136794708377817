
import React from "react";
// Import Swiper React components
import { Swiper, SwiperSlide, } from "swiper/react";
// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation"

import "../../../styles/global.scss";


// import Swiper core and required modules
import SwiperCore, {
    Navigation,
    Autoplay
} from 'swiper';
import SliderCard from "./slider-card/slider-card";
import { Typography } from "@mui/material";
import { Trans } from "react-i18next";

// install Swiper modules
SwiperCore.use([Navigation, Autoplay]);


export default function DoctorSwiperSlider({ elements }) {
    elements.sort((a) => a.name.includes("Ilirijana") ? 1 : -1)
    return (
        <>
            <Typography align="center" variant="h3" sx={{ marginBottom: "2rem" }}>
                <Trans>
                    doctors
                </Trans>
            </Typography>
            <Swiper
                style={{ width: "80%", marginTop: "1rem" }}
                navigation={true}
                spaceBetween={15}
                autoplay={{
                    delay: 3000,
                    disableOnInteraction: false
                }}
                breakpoints={{
                    // when window width is >= 320px
                    320: {
                        slidesPerView: 2,
                    },
                    // when window width is >= 640px

                    640: {
                        slidesPerView: 3,
                    },
                    // when window width is >= 1024px
                    1024: {
                        slidesPerView: 4
                    },
                    // when window width is >= 1440px
                    1440: {
                        slidesPerView: 5
                    }
                }}
            >

                {
                    elements.map(element =>
                        <SwiperSlide>
                            <SliderCard content={element} />
                        </SwiperSlide>
                    )
                }
            </Swiper>
        </>
    )
}