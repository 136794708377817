import React from 'react'
import { motion, useAnimation } from "framer-motion";
import { useInView } from 'react-intersection-observer';
import { useEffect } from 'react';

export default function Animation({ children, position }) {
    const animation = useAnimation();
    const { ref, inView } = useInView({
        threshold: 0.2
    });
    const [state, setState] = React.useState(false);

    useEffect(() => {
        if (inView && !state) {
            animation.start({
                x: 0,
                transition: {
                    type: 'spring', duration: 1.5, bounce: 0.1
                }
            });
            setState(true);
        }
        if (!inView && !state) {
            if (position === "right") {
                animation.start({ x: '100vw' })
            } else {
                animation.start({ x: '-100vw' })
            }
        }
    }, [inView]);
    return (
        <div ref={ref}>
            <motion.div animate={animation}
            >
                {children}
            </motion.div>
        </div>
    )
}
